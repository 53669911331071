import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Link, graphql } from 'gatsby';

import Container from 'src/components/Container';
import PageHeading from 'src/components/PageHeading';

const itemAnimation = {
  hidden: {},
  visible: {
    transition: { duration: 0, staggerChildren: 0.1, when: 'beforeChildren' },
  },
};

const Artmap = ({ data }) => (
  <Container size="1100px">
    <PageHeading>Art Map</PageHeading>
    <Items initial="hidden" animate="visible" variants={itemAnimation}>
      {data.allMdx.edges.map(({ node }) => (
        <Item key={node.id} {...node.frontmatter} />
      ))}
    </Items>
  </Container>
);

const Item = ({ slug, city, country, date, thumb, thumbStencil }) => (
  <motion.div
    variants={{
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0 },
    }}
  >
    <ItemLink to={`/art-map/${slug}`}>
      <Thumb img={thumb.childImageSharp.fixed.src} stencil={thumbStencil} />
      <Date>{date}</Date>
      <Location>
        <h2>{city}</h2>
        <h3>{country}</h3>
      </Location>
    </ItemLink>
  </motion.div>
);

const Items = styled(motion.div)`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-device-width: 480px) {
    grid-template-columns: 1fr;
    font-size: 14px;
  }
`;

const STENCIL_CLIPS = {
  circle: 'circle(50% at center)',
  triangle: 'polygon(50% 5%, 0 95%, 100% 95%)',
};

const Thumb = styled('div')`
  height: 100%;
  width: 100%;
  background-image: url(${p => p.img});
  background-size: cover;
  ${p => STENCIL_CLIPS[p.stencil] && `clip-path: ${STENCIL_CLIPS[p.stencil]}`};
`;

const ItemLink = styled(Link)`
  color: #000;
  display: grid;
  grid-template-columns: 100px 1rem 1fr;
  grid-gap: 1rem;
  align-items: center;
  height: 120px;
  padding: 10px;
  margin: -10px;
  transition: box-shadow 300ms;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  }
`;

const Date = styled('div')`
  font-size: 0.9rem;
  transform: rotate(90deg);
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Location = styled('div')`
  letter-spacing: 0.25rem;
  h2,
  h3 {
    margin: 0;
    font-size: 1rem;
  }
  h2 {
    margin-bottom: 0.1rem;
  }
  h3 {
    font-weight: normal;
  }
`;

export default Artmap;

export const query = graphql`
  query {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/.*/index.mdx/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            city
            country
            date(formatString: "YYYY MMM DD")
            thumbStencil
            thumb {
              childImageSharp {
                fixed(width: 300, cropFocus: CENTER) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
